.flip-containerFoot {
    perspective: 700px;
  }
  
  .flip-innerFoot {
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-containerFoot:hover .flip-innerFoot {
    transform: rotateX(180deg);
    background-color: #dbe7e8;
  }
  
  .flip-frontFoot,
  .flip-backFoot {
    backface-visibility: hidden;    
  }
  
  .flip-frontFoot {
    color: #1b3d38;
  }
  
  .flip-backFoot {
    background-color:#dbe7e8;
    color: #F6B092;
    transform: rotateX(180deg);
  }