.marquee {
    position: relative;
    max-width: 100%;
    height: 206px;
    
  }
  
  .track {
    position: absolute;
    white-space: nowrap;
  }
  