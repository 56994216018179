.flip-container {
    perspective: 700px;
  }
  
  .flip-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-container:hover .flip-inner {
    transform: rotateX(180deg);
    background-color: #F6B092;
  }
  
  .flip-front,
  .flip-back {
    backface-visibility: hidden;    
  }
  
  .flip-front {
    color: #F6B092;
  }
  
  .flip-back {
    background-color: #F6B092;
    color: #dbe7e8;
    transform: rotateX(180deg);
  }
  
  .nake {
    -webkit-text-fill-color: #dbe7e8;
    -webkit-text-stroke-width: .8px;
    -webkit-text-stroke-color: #1b3d38;
  }