.aa {
    background-image: linear-gradient(to right, #e3b505, #e3b505 50%, #dbe7e8 50%);
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 18px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
}

.ab {
    background-image: linear-gradient(to right, #e3b505, #e3b505 50%, #F6B092 50%);
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 18px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
}

.aa:hover {
    background-position: 0;
}

