.flip-animation {
    display: inline-block;
    opacity: 0;
    transform: rotateX(90deg);
    animation: flip 0.8s forwards;
  }
  
  @keyframes flip {
    0% {
      opacity: 0;
      transform: rotateY(90deg);
    }
    100% {
      opacity: 1;
      transform: rotateY(0);
    }
  }

.grass {
    background: url("../assets/images/clouds.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@media only screen and (max-width: 600px) {
    .grass {
        background-attachment: scroll;
    }
}

.grass1 {
    background: url("../assets/images/clouds.jpeg");
    transform: scaleY(-1);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@media only screen and (max-width: 600px) {
    .grass1 {
        background-attachment: scroll;
    }
}

.grass3 {
    transform: scaleY(-1);
}

.grass4 {
    background: url("../assets/images/nakedPool-min.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@media only screen and (max-width: 600px) {
    .grass4 {
        background-attachment: scroll;
        background-size: cover;
    }
}

.grass2 {
    background: url("../assets/images/nakedPool-min.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@media only screen and (max-width: 600px) {
    .grass2 {
        background-attachment: scroll;
    }
}

.popup {
    background: url("../assets/images/kimchiPink.png");
    background-position: center;
    background-size: contain;
    background-attachment: fixed;
    opacity: 1;

}

@media only screen and (max-width: 600px) {
    .popup {
        background-position: -35px 10px;
        background-attachment: fixed;
        background-size: 465px;
    }
}

.merch {
    background: url("../assets/images/orangeDudeWhite.png");
    background-position: 60px;
    background-size: contain;
    background-attachment: fixed;
    opacity: 1;

}

@media only screen and (max-width: 600px) {
    .merch {
        background-position: -35px 10px;
        background-attachment: fixed;
        background-size: 465px;
    }
}

.bread {
    background: url("../assets/images/milkBreadColors.png");
    background-position: 60px;
    background-size: contain;
    background-attachment: fixed;
    opacity: 1;

}

@media only screen and (max-width: 600px) {
    .bread {
        background-position: -35px 10px;
        background-attachment: fixed;
        background-size: 465px;
    }
}

.catering {
    background: url("../assets/images/catering.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    opacity: 1;

}

@media only screen and (max-width: 600px) {
    .catering {
        background-attachment: scroll;

    }
}

.contact {
    background: url("../assets/images/logoGreen.png");
    background-size: 40%;
    background-repeat: repeat;
    background-position: center;
    background-attachment: fixed;
    opacity: 1;

}

@media only screen and (max-width: 600px) {
    .contact {
        background-attachment: fixed;
        background-size: 300px;
    }
}

.about {
    background: url("../assets/images/photo3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    opacity: 1;

}

@media only screen and (max-width: 600px) {
    .about {
        background-attachment: scroll;
    }
}

@media only screen and (max-width: 600px) {
    .menu1 {
        background-size: 50%;
    }
}

.menu1 {
    background: url("../assets/images/clouds.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
}

.menu2 {
    background: url("../assets/images/clouds.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
}

.menu {
    background: url("../assets/images/clouds.jpeg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
}

.splitContent {
    justify-content: space-between;
    min-width: 50%;
}

.col {
    flex: 1 2 0;
    max-width: 100%;
}

@media (max-width: 991.98px) {
    .title {
        text-align: center;
    }

    .cardSect {
        justify-content: center;
        min-height: 100px;
    }

    #dividerWrap {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 90%;
    }

    #dividerWrap2 {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 90%;
    }

    .item {
        display: block;
        min-width: 300;
    }

    .contentDivider {
        margin: 15px 0 15px 0;
        font-size: 20px;
    }

    .contentDivider .dividedText {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .contentDivider .dividedText:before,
    .contentDivider .dividedText:after {
        content: '';
        border-top: 2px solid;
        margin: 0 20px 0 0;
        flex: 1 0 20px;
    }

    .contentDivider .dividedText:after {
        margin: 0 0 0 20px;
    }
}

@media (min-width: 992px) {
    #dividerWrap {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 20px;
        padding: -10px 0 -10px;
        justify-content: center;
    }

    .split {
        justify-content: center;
    }

    .contentDivider {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        justify-self: center;
        height: 100%;
        min-width: 100%;
    }

    .contentDivider .dividedText:before,
    .contentDivider .dividedText:after {
        position: absolute;
        content: "";
        width: 1px;
        left: 50%;
        border-left: 2px solid black;
    }

    .contentDivider .dividedText:before {
        bottom: 50%;
        top: 0;
        margin-bottom: 20px;
    }

    .contentDivider .dividedText:after {
        top: 50%;
        bottom: 0;
        margin-top: 20px;
    }
}

.button-background-move-menu {
    letter-spacing: 1px;
    padding: 35px 40px 12px;
    outline: 0;
    border: 2px solid #e3b505;
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-background-move-menu:after {
    content: "";
    border: 2px solid #e3b505;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.5s;
}

.button-background-move-menu:hover:after {
    top: 0px;
    left: 0px;
}
.stroke {
    -webkit-text-stroke: 4px black;
}
@media only screen and (max-width: 600px) {
    .stroke {
        -webkit-text-stroke: 2px black;
    }
}
