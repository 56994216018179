.button-background-move {
    letter-spacing: 1px;
    padding: 14px 40px 12px;
    outline: 0;
    border: 2px solid #47b9c3;
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
 
  .button-background-move:after {
    content: "";
    border: 2px solid #47b9c3;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.5s;
  }
  
  .button-background-move:hover:after {
    top: 0px;
    left: 0px;
  }
  
/* .btn2 {
    position: relative;
    font-weight: 700;
    background-color: #386641;
    border-radius: 0.6em;
    display: inline-block;
    margin: 1em;
}
.btn2 .inner {
    display: inline-block;
    background-color: #faf3dd;
    border-radius: 0.6em;
    border: 0.3em solid #386641;
    padding: 1em;
    cursor: pointer;
    text-transform: uppercase;
    user-select: none;
    transform: translate3d(0.6em, -0.6em, 0);
    transition: transform 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-delay: 220ms;
}
.btn2:active .inner, .btn2.click .inner {
    transform: translate3d(0, 0, 0);
    transition: transform 120ms cubic-bezier(0.23, 1, 0.32, 1);
} */
/* .btn {
      display: inline-block;
      vertical-align: middle;
   
      transition: .5s ease-out;
      text-decoration: none;
      cursor: pointer;
    } */
/* .btn2:hover .inner:hover {
    background-color: #386641;
    color: #faf3dd;

} */